/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { FaCalendarPlus, FaRegFilePdf } from 'react-icons/fa6';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
// HELPERS AND SERVICES
import * as meetingService from '../../../../services/management/meetingService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const MeetingListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = () => {
    setIsLoading(true);
    meetingService.getAllMeetings().then((res) => {
      setMeetings(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleDeleteMeeting = (meetingId) => {
    setIsLoading(true);
    meetingService.deleteMeeting(meetingId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchMeetings();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    rowsPerPage: 40,
    sortOrder: {
      name: 'date',
      direction: 'desc',
    },
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                history.push(`/admin/management/edit-meeting/${meetings[dataIndex].id}`);
              }}
            />
            {auth.isAdmin && (
              <Button
                text="Delete"
                className="danger--bg ml-5"
                size="xxs"
                onClick={() => {
                  showConfirmModal({
                    title: 'Remove Meeting',
                    text: `Are you sure you want to remove the ${moment(meetings[dataIndex].date).format('MMMM D, YYYY')} meeting?`,
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      handleDeleteMeeting(meetings[dataIndex].id);
                    }
                  });
                }}
              />
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const date = moment(meetings[dataIndex].date).format('MMMM D, YYYY');
          const startTime = meetings[dataIndex].startTime ? moment(meetings[dataIndex].startTime, 'HH:mm').format('h:mm A') : '';
          const endTime = meetings[dataIndex].endTime ? moment(meetings[dataIndex].endTime, 'HH:mm').format('h:mm A') : '';
          // const dotw = moment(meetings[dataIndex].date).format('dddd');

          return (
            <CustomBlock className="">
              <p className="mb-5">{date}</p>
              <p className="grey--clr fw-400 size-xxs">{`${startTime}${startTime && endTime && ' - '}${endTime}`}</p>
              {/* <p className="grey--clr fw-400 size-xxs">{dotw}</p> */}
            </CustomBlock>
          );
        }
      },
    },
    {
      name: 'minutesFilePath',
      label: 'Attachments',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { minutesFilePath, agendasFilePath } = meetings[dataIndex];
          const minutesFileURL = `${process.env.REACT_APP_API_URL}Attachments/${minutesFilePath}`;
          const agendasFileURL = `${process.env.REACT_APP_API_URL}Attachments/${agendasFilePath}`;

          return (
            <CustomBlock className="meeting-listing__attachments">
              <CustomBlock
                title={`${minutesFilePath ? '✓ Minutes Document Uploaded' : '✗ Minutes Not Uploaded'}`}
                className={`attachment p-2 ${minutesFilePath ? 'hasAttachment' : ''}`}
                onClick={() => minutesFilePath && window.open(minutesFileURL, '_blank')}
              >
                <FaRegFilePdf size="20" />
                <p className="size-xxs fw-400">Minutes</p>
              </CustomBlock>
              <CustomBlock
                title={`${agendasFilePath ? '✓ Agenda Document Uploaded' : '✗ Agenda Not Uploaded'}`}
                className={`attachment p-2 ${agendasFilePath ? 'hasAttachment' : ''}`}
                onClick={() => agendasFilePath && window.open(agendasFileURL, '_blank')}
              >
                <FaRegFilePdf size="20" className="" />
                <p className="size-xxs fw-400">Agenda</p>
              </CustomBlock>
            </CustomBlock>
          );
        }
      },
    },
    {
      name: 'date',
      label: 'Status',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const meetingDate = moment(meetings[dataIndex].date);
          const now = moment();
          const isUpcoming = meetingDate.isAfter(now);

          return (
            <CustomBlock>
              <p className="">{isUpcoming ? 'Upcoming' : 'Past'}</p>
            </CustomBlock>
          );
        }
      },
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = meetings[dataIndex];

          return (
            <CustomBlock
              style={{
                width: 'fit-content',
                padding: '3px 9px',
                borderRadius: '8px',
                backgroundColor: isActive ? 'green' : 'red',
              }}
            >
              <p className="white--clr size-xs">{isActive ? 'Yes' : 'No'}</p>
            </CustomBlock>
          );
        }
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Meetings"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Meeting' : ''}
                primaryButtonIconLeft={<FaCalendarPlus className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  history.push('/admin/management/create-meeting');
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={meetings}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(MeetingListingPage);