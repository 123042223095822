/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
import Image from '../../../../../components/Image';
// ASSETS
import LogoLight from '../../../../../assets/client/logo_light.svg';

const HomepageIntro = () => {
  const remove = '';

  return (
    <CustomBlock className="content-section">

      <CustomBlock
        className="d-flex align-items-end flex-wrap gap-15 rounder p-5 w-100"
        style={{
          // backgroundImage: `url('${Pattern1}')`,
          backgroundColor: '#633251',
          // backgroundBlendMode: 'soft-light',
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
        }}
      >
        {/* <CustomBlock
          style={{ width: '180px', opacity: '0.8' }}
          className="mr-40"
        >
          <Image
            source={LogoLight}
            alt=""
          />
        </CustomBlock> */}

        <Fade direction="left">
          <h4 className="size-xxl fw-800 secondary-lighter--clr">Integrity <span className="size-xl fw-600">&</span> Honesty</h4>
        </Fade>

        <Fade delay={700} direction="up">
          <p className="size-lg fw-500 secondary-lighter--clr" style={{ lineHeight: 'normal' }}>Over Services</p>
        </Fade>

        <Fade delay={1400}>
          <p className="size-lg fw-500 secondary-lightest--clr mt-20">Dedicated to Our People, Community, and Employees</p>
        </Fade>
      </CustomBlock>
      {/* <Image
          source={IllustrationParishMap}
          containerClassName="container-1"
          className="logo"
        /> */}
      {/* <CustomBlock className="container-2">
          <h3 className="header size-xl fw-700 dark-grey--clr pb-20">District 2</h3>
          <p className="description mid-grey--clr size-mdlg">
            At Livingston Parish Gravity Drainage District 2, our focus extends beyond simple upkeep. We&apos;re wholeheartedly <span className="fw-600">committed to ensuring smooth, sustainable water management for the well-being and prosperity of our communities</span>.
          </p>
          <Fade direction="right">
            <Button
              type="button"
              round-corners
              text="Learn More"
              to="/about-livingston-parish-drainage-district-2"
              className="secondary--sbg mt-40"
            />
          </Fade>
        </CustomBlock> */}
    </CustomBlock>
  );
};

export default HomepageIntro;