/* eslint-disable import/no-cycle */
import axios from 'axios';
import constants from '../constants/constants';
import { errorHandler, requestHandler, successHandler } from './interceptor/axiosInterceptor';

const axiosInstance = axios.create({ baseURL: constants.API_URL });

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request)
);

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (err) => errorHandler(err)
);

export default axiosInstance;