/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable object-curly-newline */
/* eslint-disable no-plusplus */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// ICONS
import { BsFillImageFill } from 'react-icons/bs';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import CustomBlock from './CustomBlock';

const styles = {
  masonryGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-10px',
  },
  masonryColumn: {
    padding: '0 10px',
    boxSizing: 'border-box',
  },
  masonryItem: {
    marginBottom: '20px',
    boxSizing: 'border-box',
  },
  masonryImage: {
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
  },
  placeholderImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    color: '#666',
    fontSize: '14px',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    userSelect: 'none',
  },
};

const Image = ({ src, alt, style }) => {
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
  };

  if (imageError) {
    return (
      <CustomBlock className="d-flex flex-column justify-content-center align-items-center gap-5" style={styles.placeholderImage}>
        <BsFillImageFill size={42} className="mid-grey--clr" />
        <p className="mid-grey--clr">
          Image Not Found
        </p>
      </CustomBlock>
    );
  }

  return <img src={src} alt={alt} style={style} onError={handleError} />;
};

const MasonryGrid = (props) => {
  const { images, customInsert } = props;
  const [columns, setColumns] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100) setColumns(4);
      else if (window.innerWidth > 700) setColumns(3);
      else if (window.innerWidth > 500) setColumns(2);
      else setColumns(1);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columnWrapper = {};
  const result = [];
  // const insertPosition = Math.ceil(images.length / 3);
  const insertPosition = 2;

  // Create column arrays
  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = [];
  }

  // Distribute images into columns
  for (let i = 0; i < images.length; i++) {
    if (i === insertPosition) {
      columnWrapper[`column${i % columns}`].push(
        <CustomBlock key="customInsert" style={styles.masonryItem}>
          <Fade delay={200}>
            {customInsert}
          </Fade>
        </CustomBlock>
      );
    }
    const columnIndex = i % columns;
    columnWrapper[`column${columnIndex}`].push(
      <CustomBlock key={i} style={styles.masonryItem}>
        <Fade delay={300}>
          <Image
            src={images[i].src}
            alt={images[i].alt}
            style={styles.masonryImage}
          />
        </Fade>
      </CustomBlock>
    );
  }

  // Wrap each column in a div
  for (let i = 0; i < columns; i++) {
    result.push(
      <CustomBlock
        key={`column${i}`}
        style={{
          ...styles.masonryColumn,
          width: `${100 / columns}%`,
        }}
      >
        {columnWrapper[`column${i}`]}
      </CustomBlock>
    );
  }

  return (
    <CustomBlock style={styles.masonryGrid}>
      {result}
    </CustomBlock>
  );
};

export default MasonryGrid;