/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
// ICONS
import { FaMobileAlt, FaTabletAlt } from 'react-icons/fa';
import { IoDesktopOutline } from 'react-icons/io5';
import { BsLaptop } from 'react-icons/bs';
import { MdDesktopWindows, MdTv } from 'react-icons/md';
// COMPONENTS
import CustomBlock from '../../components/CustomBlock';

const initialValues = {
  screenWidth: window.innerWidth,
  scrollPosition: window.pageYOffset,
  elementStyles: [],
};

const FluidTypographyTest = (props) => {
  const { className } = props;
  const [screenInfo, setScreenInfo] = useState(initialValues);
  const fontSizes = ['xxxl', 'xxl', 'xl', 'lg', 'mdlg', 'md', 'sm', 'xs', 'xxs', 'xxxs'].reverse();

  const breakpoints = {
    Mobile: { min: 320, max: 480 },
    Tablet: { min: 481, max: 768 },
    Laptop: { min: 769, max: 1024 },
    Desktop: { min: 1025, max: 1200 },
    'Large Desktop': { min: 1201, max: Infinity }
  };

  const breakpointIcons = {
    Mobile: <FaMobileAlt className="white--clr" size="24" />,
    Tablet: <FaTabletAlt className="white--clr" size="24" />,
    Laptop: <BsLaptop className="white--clr" size="28" />,
    Desktop: <IoDesktopOutline className="white--clr" size="28" />,
    'Large Desktop': <MdTv className="white--clr" size="30" />,
  };

  useEffect(() => {
    getScreenInfo();

    // Setup event listeners for resize and scroll
    window.addEventListener('resize', getScreenInfo);
    window.addEventListener('scroll', getScreenInfo);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('resize', getScreenInfo);
      window.removeEventListener('scroll', getScreenInfo);
    };
  }, []);

  const getScreenInfo = () => {
    // const sizes = ['giant', 'xxxxl', 'xxxl', 'xxl', 'xl', 'lg', 'mdlg', 'md', 'sm', 'xs', 'xxs', 'xxxs', 'xxxxs', 'xxxxxs', 'tiny'];
    const screenWidth = window.innerWidth;

    const currentBreakpointName = Object.entries(breakpoints).reduce((acc, [name, { min, max }]) => {
      if (screenWidth >= min && screenWidth <= max) return name;
      return acc;
    }, 'unknown');

    const elementStyles = fontSizes.reduce((acc, size) => {
      const element = document.querySelector(`.fluid-typography-test .typography-elements .size-${size}`);
      if (element) {
        const computedStyle = window.getComputedStyle(element);
        acc[size] = {
          fontSize: computedStyle.fontSize,
          lineHeight: computedStyle.lineHeight,
          fontFamily: computedStyle.fontFamily,
          fontWeight: computedStyle.fontWeight,
          color: computedStyle.color,
          textAlign: computedStyle.textAlign,
          letterSpacing: computedStyle.letterSpacing,
          textTransform: computedStyle.textTransform,
        };
      }
      return acc;
    }, {});

    const info = {
      screenWidth: window.innerWidth,
      scrollPosition: window.pageYOffset,
      currentBreakpointName,
      elementStyles,
    };

    // console.log('info', info);

    setScreenInfo(info);
  };

  const getBreakpointIcon = (breakpoint) => breakpointIcons[breakpoint] || null;

  return (
    <CustomBlock className={`fluid-typography-test ${className} dark--bg rounder`}>
      <p className="size-md fw-600 flex-center white--clr mb-10">Fluid Typography Test</p>

      <CustomBlock className="d-flex align-items-center">
        <CustomBlock className="d-flex flex-column align-items-center">
          <p className="d-flex align-items-center white--clr gap-10">
            Screen Width: <span className="size-lg fw-600 white--clr">{screenInfo.screenWidth}px</span>
          </p>

          <p className="d-flex align-items-center white--clr gap-10">
            Scroll Position: <span className="size-lg fw-600 white--clr">{screenInfo.scrollPosition}px</span>
          </p>
        </CustomBlock>

        <CustomBlock className="flex-start" style={{ opacity: '0.8' }}>
          {getBreakpointIcon(screenInfo.currentBreakpointName)}
          <p className="flex-start ml-5 white--clr">{screenInfo.currentBreakpointName}</p>
        </CustomBlock>
      </CustomBlock>

      <p className="size-md white--clr fw-600 flex-center mt-10 mb-10">Font Sizes</p>
      <CustomBlock className="typography-elements d-flex">
        {fontSizes.map((fontSize) => (
          <CustomBlock className="typography-element">
            <CustomBlock className="typography-element__details">
              <p className="post-tag white--clr mb-1">{fontSize}</p>
              {screenInfo.elementStyles && screenInfo.elementStyles[fontSize] && (
                <CustomBlock className="props">
                  <p title="Font Size" className="prop white--clr size-xxxs">{screenInfo.elementStyles[fontSize].fontSize}</p>
                  <p title="Line Height" className="prop white--clr size-xxxs">{screenInfo.elementStyles[fontSize].lineHeight}</p>
                  <p title="Font Family" className="prop white--clr size-xxxs">{screenInfo.elementStyles[fontSize].fontFamily}</p>
                </CustomBlock>
              )}
            </CustomBlock>

            <CustomBlock className="typography-element__display">
              <p className={`size-${fontSize} white--clr`}>{fontSize}</p>
            </CustomBlock>
          </CustomBlock>
        ))}
      </CustomBlock>

    </CustomBlock>
  );
};

export default FluidTypographyTest;