/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */
// DEPENDENCIES
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
// ASSETS
import 'react-before-after-slider-component/dist/build.css';
// SERVICES & HELPERS
import constants from '../../../../../constants/constants';

const ServicesIntro = () => {
  const history = useHistory();
  const beforeAfterImageSetLength = 2;

  const beforeAfterImages = Array.from({ length: beforeAfterImageSetLength }, (_, i) => ({
    id: i,
    firstImage: {
      imageUrl: `${constants.API_URL}Attachments/ServiceImages/BeforeAfterSlider/image-${i + 1}-before.png`,
      alt: `Before Image ${i + 1}`
    },
    secondImage: {
      imageUrl: `${constants.API_URL}Attachments/ServiceImages/BeforeAfterSlider/image-${i + 1}-after.png`,
      alt: `After Image ${i + 1}`
    }
  }));

  const preventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  const disableScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.touchAction = 'none';
    document.addEventListener('touchmove', preventDefault, { passive: false });
  }, [preventDefault]);

  const enableScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.touchAction = '';
    document.removeEventListener('touchmove', preventDefault);
  }, [preventDefault]);

  const handleSliderModeChange = useCallback((newMode) => {
    if (newMode === 'move') {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [disableScroll, enableScroll]);

  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, [enableScroll]);

  return (
    <CustomBlock className="content-section">
      <CustomBlock className="d-flex flex-column">
        <CustomBlock className="">
          <Fade delay={300}>
            <h3 className="header size-xl fw-800 primary--clr pb-15">Transformations</h3>
            <p className="description mid-grey--clr size-md">See the dramatic changes we&apos;ve achieved with our restoration services.</p>
            <Button
              hasRoundCorners
              type="button"
              text="View Before & After Images"
              onClick={() => history.push('/services-gallery')}
              className="secondary-lighter--bg size-xs mt-30"
              style={{ width: 'fit-content' }}
            />
          </Fade>
        </CustomBlock>

        <Container fluid className="m-0 p-0">
          <Row className="Xjustify-content-between g-5">
            {beforeAfterImages.map((img) => (
              <Col
                key={img.id}
                sm={12}
                lg={6}
                className="pl-0 pr-md-0"
              >
                <Fade delay={300}>
                  <CustomBlock
                    className="rounder overflow-hidden p-0 xmr-4"
                    style={{
                      border: '3px solid #FFF',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    }}
                  >
                    <ReactBeforeSliderComponent
                      firstImage={img.secondImage}
                      secondImage={img.firstImage}
                      onChangeMode={handleSliderModeChange}
                    />
                  </CustomBlock>
                </Fade>
              </Col>
            ))}
          </Row>
        </Container>
      </CustomBlock>
    </CustomBlock>
  );
};

export default ServicesIntro;