/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-curly-newline */
// DEPENDENCIES
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import Banner from '../../../components/Banner';
import MasonryGrid from '../../../components/MasonryGrid';
import CustomBlock from '../../../components/CustomBlock';
// ASSETS
import Button from '../../../components/Button';
import BannerImage from '../../../assets/client/default-banner.png';
import 'react-before-after-slider-component/dist/build.css';
// SERVICES & HELPERS
import constants from '../../../constants/constants';
import * as settingService from '../../../services/management/settingService';

const ServiceGalleryImagesPage = () => {
  const history = useHistory();
  const [serviceImagesLength, setServiceImagesLength] = useState(null);
  const [beforeAfterImageSetLength, setBeforeAfterImageSetLength] = useState(null);

  const beforeAfterImages = Array.from({ length: beforeAfterImageSetLength }, (_, i) => ({
    id: i,
    firstImage: {
      imageUrl: `${constants.API_URL}Attachments/ServiceImages/BeforeAfterSlider/image-${i + 1}-before.png`,
      alt: `Before Image ${i + 1}`
    },
    secondImage: {
      imageUrl: `${constants.API_URL}Attachments/ServiceImages/BeforeAfterSlider/image-${i + 1}-after.png`,
      alt: `After Image ${i + 1}`
    }
  }));

  const serviceImages = Array.from({ length: serviceImagesLength }, (_, i) => ({
    id: i,
    src: `${constants.API_URL}Attachments/ServiceImages/image-${i + 1}.png`,
    alt: `Local Restoration Service Image ${i + 1}`
  }));

  const getSettings = () => {
    const serviceImagesKey = 'ServicesGallery_ImagesLength';
    const beforeAfterImagesKey = 'ServicesGallery_BeforeAfterImageSetLength';

    settingService.getSettingByKey(serviceImagesKey).then((res) => {
      setServiceImagesLength(res);
    });

    settingService.getSettingByKey(beforeAfterImagesKey).then((res) => {
      setBeforeAfterImageSetLength(res);
    });
  };

  useEffect(() => {
    if (!serviceImagesLength || !beforeAfterImageSetLength) {
      getSettings();
    }
  }, []);

  const preventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  const disableScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.touchAction = 'none';
    document.addEventListener('touchmove', preventDefault, { passive: false });
  }, [preventDefault]);

  const enableScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.touchAction = '';
    document.removeEventListener('touchmove', preventDefault);
  }, [preventDefault]);

  const handleSliderModeChange = useCallback((newMode) => {
    if (newMode === 'move') {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [disableScroll, enableScroll]);

  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, [enableScroll]);

  return (
    <>
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Services Gallery"
        imageSource={BannerImage}
      />
      <CustomBlock className="main-content">
        {/* INTRO */}
        <CustomBlock className="content-section">
          <CustomBlock className="">
            <Fade>
              <h3 className="header size-xl fw-800 primary--clr pb-15">Our Restoration Work</h3>
              <p className="description mid-grey--clr size-md">
                Browse our gallery showcasing Local Restoration&apos;s work in water, fire, and mold remediation. As a veteran-owned and community-focused business, we are dedicated to restoring your property with integrity and honesty.
              </p>
              <Button
                hasRoundCorners
                type="button"
                text="View Services"
                onClick={() => history.push('/local-restoration-services')}
                className="secondary-lighter--bg size-xs mt-30"
                style={{ width: 'fit-content' }}
              />
            </Fade>
          </CustomBlock>
        </CustomBlock>

        {/* BEFORE AND AFTER SLIDERS */}
        <CustomBlock className="content-section">
          <Container fluid className="m-0 p-0 mb-60">
            <Row className="Xjustify-content-between g-5">
              <Col
                sm={12}
                lg={6}
                className="d-flex flex-column justify-content-center pl-0"
              >
                <Fade delay={300}>
                  <h3 className="header size-xl fw-800 primary--clr pb-20">Transformations</h3>
                  <p className="description mid-grey--clr size-md">See the dramatic changes we&apos;ve achieved with our restoration services.</p>
                </Fade>
              </Col>
              {beforeAfterImages.map((img) => (
                <Col
                  key={img.id}
                  sm={12}
                  lg={6}
                  className="pl-0 pr-md-0"
                >
                  <Fade delay={300}>
                    <CustomBlock
                      className="rounder overflow-hidden p-0 xmr-4"
                      style={{
                        border: '3px solid #FFF',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                      }}
                    >
                      <ReactBeforeSliderComponent
                        firstImage={img.secondImage}
                        secondImage={img.firstImage}
                        onChangeMode={handleSliderModeChange}
                      />
                    </CustomBlock>
                  </Fade>
                </Col>
              ))}
            </Row>
          </Container>
        </CustomBlock>

        {/* MASONRY GRID */}
        <CustomBlock className="content-section">
          <MasonryGrid
            images={serviceImages}
            customInsert={(
              <CustomBlock className="d-flex flex-column justify-content-center pl-10 pr-10 pt-20 pb-20">
                <h3 className="header size-xl fw-800 primary--clr pb-20">Our Work in Action</h3>
                <p className="description mid-grey--clr size-mdlg">Browse our gallery to see the exceptional results we deliver and how we can restore your property.</p>
              </CustomBlock>
            )}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default ServiceGalleryImagesPage;