/* eslint-disable max-len */
/* eslint-disable no-use-before-define */

// DEPENDENCIES
// import React, { useEffect, useState } from 'react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import Slider from 'react-slick';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
// import TextBlock from '../../../components/TextBlock';
// CMS COMPONENTS
import Banner from '../../../components/Banner';
import ContentSection from '../../../components/ContentSection';
import HomePage from './HomePage/HomePage';
// SERVICES AND HELPERS
import * as homeService from '../../../services/cms/homeService';
import constants from '../../../constants/constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const initialDynamicPageModel = {
  bannerColor: '#ffffff',
  bannerImageFilePath: '',
  description: null,
  id: 0,
  isBannerVisible: false,
  keywords: '',
  name: '',
  pageContentSectionBanners: [],
  title: null,
  url: '',
};

const DynamicCMSPage = (props) => {
  const { dynamicPageId, history, location, isBlog } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicPageModel, setDynamicPageModel] = useState(initialDynamicPageModel);

  useEffect(() => {
    if (isBlog) {
      getDynamicBlogContent();
    } else {
      getDynamicPageContent();
    }
  }, [location]);

  const getDynamicPageContent = () => {
    setIsLoading(true);
    homeService.getDynamicPage(dynamicPageId).then((res) => {
      setDynamicPageModel(res);
    }).catch().finally(() => setIsLoading(false));
  };

  const getDynamicBlogContent = () => {
    setIsLoading(true);
    homeService.getDynamicBlog(dynamicPageId).then((res) => {
      setDynamicPageModel(res);
    }).catch().finally(() => setIsLoading(false));
  };

  const buttonAction = (actionType, link) => {
    switch (actionType) {
      case constants.CONTENT_SECTION.ButtonActionTypeLink:
        window.open(link, '_blank');
        break;
      case constants.CONTENT_SECTION.ButtonActionTypeEmail:
        window.location.href = `mailto:${link}`;
        break;
      case constants.CONTENT_SECTION.ButtonActionTypePage:
        history.push(link);
        break;
      case constants.CONTENT_SECTION.ButtonActionTypePhoneNumber:
        window.location.href = `tel:${link}`;
        break;
      default:
        break;
    }
  };

  const generateContent = (model) => {
    switch (model.type) {
      case constants.CONTENT_SECTION.ContentSectionTypeDefault:
      case constants.CONTENT_SECTION.ContentSectionTypeReversed:
      case constants.CONTENT_SECTION.ContentSectionTypeFullWidth:
      case constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded:
      case constants.CONTENT_SECTION.ContentSectionTypeTextOnly:
        return (
          <ContentSection
            // isTitleUppercase
            isFullWidthUnpadded={model.type === constants.CONTENT_SECTION.ContentSectionTypeFullWidth}
            isFullWidth={model.type === constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded}
            isReversed={model.alignment === constants.CONTENT_SECTION.RightAlignment}
            isCentered={model.alignment === constants.CONTENT_SECTION.CenterAlignment}
            isFloatingCard={model.type === constants.CONTENT_SECTION.ContentSectionTypeCard}
            isTextOnly={model.isTextOnly}
            hasRoundCorners
            title={model.title}
            imageSource={`${process.env.REACT_APP_API_URL}Attachments${model.imagePath}`}
            description={model.description}
            primaryActionText={model.primaryButtonText}
            primaryActionOnClick={() => buttonAction(model.primaryButtonActionType, model.primaryButtonLink)}
            secondaryActionText={model.secondaryButtonText}
            secondaryActionOnClick={() => buttonAction(model.secondaryButtonActionType, model.secondaryButtonLink)}
          />
        );
      case constants.CONTENT_SECTION.ContentSectionTypeCard:
        return (
          <ContentSection
            isFloatingCard
            hasCardBackgroundImage={model.imagePath}
            isReversed={model.alignment === constants.CONTENT_SECTION.RightAlignment}
            isCentered={model.alignment === constants.CONTENT_SECTION.CenterAlignment}
            hasRoundCorners
            title={model.title}
            titleColor="white--clr"
            imageSource={`${process.env.REACT_APP_API_URL}Attachments${model.imagePath}`}
            description={model.description}
            descriptionColor="white--clr"
            primaryActionText={model.primaryButtonText}
            primaryActionOnClick={() => buttonAction(model.primaryButtonActionType, model.primaryButtonLink)}
            primaryActionColor="dark--clr white--bg"
            secondaryActionText={model.secondaryButtonText}
            secondaryActionColor="white--clr warning--bg"
            secondaryActionOnClick={() => buttonAction(model.secondaryButtonActionType, model.secondaryButtonLink)}
          />
        );
      case constants.CONTENT_SECTION.ContentSectionTypeBanner:
        return (
          <Banner
            isTitleUppercase
            isReversed={model.alignment === constants.CONTENT_SECTION.RightAlignment}
            isCentered={model.alignment === constants.CONTENT_SECTION.CenterAlignment}
            title={model.title}
            bannerBackgroundColor={dynamicPageModel.bannerColor}
            imageSource={`${process.env.REACT_APP_API_URL}Attachments${model.imagePath}`}
            description={model.description}
            primaryActionText={model.primaryButtonText}
            primaryActionOnClick={() => buttonAction(model.primaryButtonActionType, model.primaryButtonLink)}
            secondaryActionText={model.secondaryButtonText}
            secondaryActionOnClick={() => buttonAction(model.secondaryButtonActionType, model.secondaryButtonLink)}
          />
        );
      case constants.CONTENT_SECTION.ContentSectionCustom:
        return (
          <CustomBlock className="custom-content-section">
            <Fade delay={200}>
              <CustomBlock dangerouslySetInnerHTML={{ __html: model.rawHTML }} />
            </Fade>
          </CustomBlock>
        );
      case constants.CONTENT_SECTION.ContentSectionDynamicBanner:
        if (dynamicPageModel.url === '/home') {
          return '';
        }
        return (
          <Slider settings={
            {
              dots: true,
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          >
            {
              model.bannerItems.map((item) => (
                <Banner
                  isTitleUppercase
                  isReversed={item.alignment === constants.CONTENT_SECTION.RightAlignment}
                  isCentered={item.alignment === constants.CONTENT_SECTION.CenterAlignment}
                  title={item.title}
                  imageSource={`${process.env.REACT_APP_API_URL}Attachments${item.imagePath}`}
                  description={item.description}
                  bannerBackgroundColor={dynamicPageModel.bannerColor}
                  primaryActionText={item.primaryButtonText}
                  primaryActionOnClick={() => buttonAction(model.primaryButtonActionType, model.primaryButtonLink)}
                  secondaryActionText={item.secondaryButtonText}
                  secondaryActionOnClick={() => buttonAction(model.secondaryButtonActionType, model.secondaryButtonLink)}
                />
              ))
            }
          </Slider>
        );
      default:
        return <p>Loading...</p>;
    }
  };

  return (
    <>
      <Helmet>
        <title>{dynamicPageModel.title || dynamicPageModel.name}</title>
        <meta
          name="description"
          content={dynamicPageModel.description || ''}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={dynamicPageModel.keywords || ''}
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content={dynamicPageModel.title || dynamicPageModel.name}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={dynamicPageModel.description || ''}
          data-react-helmet="true"
        />
        {dynamicPageModel.bannerImageFilePath && (
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_API_URL}Attachments/${dynamicPageModel.bannerImageFilePath}`}
            data-react-helmet="true"
          />
        )}
        <link
          rel="canonical"
          href={`${window.location.origin}${dynamicPageModel.url}`}
          data-react-helmet="true"
        />
      </Helmet>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock>
        {/* <CustomBlock> */}
        {/* PAGE SPECIFICS */}
        {
          dynamicPageModel.url === '/home' && (
            <HomePage
              dynamicPageModel={dynamicPageModel}
              buttonAction={buttonAction}
              {...props}
            />
          )
        }
        {
          dynamicPageModel.isBannerVisible && !(dynamicPageModel.addedBy && dynamicPageModel.addedOn)
          && (
            <Banner
              isTitleUppercase
              isDynamicBanner
              titlePosition="center"
              // title={dynamicPageModel.bannerTitle || dynamicPageModel.title || dynamicPageModel.name}
              title={dynamicPageModel.bannerTitle}
              imageSource={`${process.env.REACT_APP_API_URL}Attachments/${dynamicPageModel.bannerImageFilePath}`}
              bannerBackgroundColor={dynamicPageModel.bannerColor}
            />
          )
        }
        <CustomBlock className="main-content">
          {
            dynamicPageModel.pageContentSectionBanners
              ? (
                dynamicPageModel.pageContentSectionBanners.map((section) => (
                  generateContent((section.bannerId) ? { ...section.banner, type: constants.CONTENT_SECTION.ContentSectionDynamicBanner } : section.contentSection)
                ))
              )
              : (
                dynamicPageModel.blogContentSectionBanners.map((section) => (
                  generateContent((section.bannerId) ? { ...section.banner, type: constants.CONTENT_SECTION.ContentSectionDynamicBanner } : section.contentSection)
                ))
              )
          }
        </CustomBlock>
        {/* </CustomBlock> */}
      </CustomBlock>
    </>
  );
};

export default DynamicCMSPage;