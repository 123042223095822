/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-undef */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
import Image from '../../../../components/Image';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/user/RegisterUserValidator';
import UpdatePasswordValidator from '../../../../helpers/validators/user/UpdatePasswordValidator';
import EditUserValidator from '../../../../helpers/validators/user/EditUserValidator';
import * as settingService from '../../../../services/management/settingService';
import * as pageService from '../../../../services/cms/pageService';

const SettingsListingPage = (props) => {
  const { showAlert, cmsInfo, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  // SETTINGS
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState({});
  // PAGE SEO
  const [isPageSEOModalVisible, setIsPageSEOModalVisible] = useState(false);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  // GENERAL
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    if (!pages.length > 0) {
      getPages();
    }
  }, []);

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = () => {
    setIsLoading(true);
    settingService.getSettings().then((res) => {
      setSettings(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getPages = async () => {
    setIsLoading(true);
    try {
      const res = await pageService.getAllPages(true);
      
      const detailedPages = await Promise.all(res.map(async (page) => {
        try {
          const pageDetails = await pageService.getPageById(page.id);
          return { ...page, ...pageDetails };
        } catch (ex) {
          showAlert({ text: `Error fetching details for page ${page.id}: ${ex.message}`, state: 'error' });
          return page;
        }
      }));
      
      setPages(detailedPages);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      key: '',
      value: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const updatedSetting = { ...selectedSetting, value: values.value };

      settingService.saveSetting(updatedSetting)
        .then(() => {
          showAlert({ text: `${updatedSetting.key} updated successfully`, state: 'success' });
          getSettings();
          handleModalClose();
        })
        .catch((ex) => {
          showAlert({ text: ex.message, state: 'error' });
        });
    },
  });

  // const formikPageSEO = useFormik({
  //   initialValues: {
  //     name: '',
  //     title: '',
  //     keywords: '',
  //     description: '',
  //     url: '',
  //   },
  //   enableReinitialize: true,
  //   onSubmit: (values) => {
  //     const updatedPage = { ...selectedPage, ...values };
  //     console.log(updatedPage);
  //     // pageService.getPageById(updatedPage.id).then((res) => {
  //     //   console.log(res)
  //     // })
  //     setIsLoading(true);
  //     pageService.updatePage(updatedPage).then((res) => {
  //       showAlert({ message: res.message, state: 'success' });
  //       getPages();
  //       handlePageModalClose();
  //     }).catch((ex) => {
  //       showAlert({ text: ex.message, state: 'error' });
  //     }).finally(() => {
  //       setIsLoading(false);
  //     });
  //   },
  // });

  const getSetting = (id) => {
    setIsLoading(true);
    settingService.getSettingById(id).then((res) => {
      setSelectedSetting(res);
      formik.setValues({ key: res.key, value: res.value });
      setIsModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleModalClose = () => {
    setSelectedSetting([]);
    setIsModalVisible(false);
  };

  const handlePageModalClose = () => {
    setSelectedPage([]);
    setIsPageSEOModalVisible(false);
  };

  const settingsColumns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0 size-xxxs"
              size="xxs"
              onClick={() => getSetting(settings[dataIndex].id)}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'key',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: '500px',
            wordWrap: 'break-word',
          },
        }),
      },
    },
  ];

  // const pagesSEOColumns = [
  //   {
  //     name: 'id',
  //     label: 'Actions',
  //     options: {
  //       filter: false,
  //       sort: false,
  //       print: false,
  //       download: false,
  //       customBodyRenderLite: (dataIndex) => (
  //         <CustomBlock className="content-container--actions flex-start pl-0 mt-0 gap-2">
  //           <Button
  //             text="Edit"
  //             className="primary--bg ml-0 size-xxxs"
  //             size="xxs"
  //             onClick={() => {
  //               const pg = pages[dataIndex];
  //               setSelectedPage(pg);
  //               formikPageSEO.setValues({
  //                 name: pg.name,
  //                 title: pg.title,
  //                 keywords: pg.keywords,
  //                 description: pg.description,
  //                 url: pg.url
  //               });
  //               setIsPageSEOModalVisible(true);
  //             }}
  //           />
  //         </CustomBlock>
  //       )
  //     }
  //   },
  //   {
  //     name: 'name',
  //     label: 'Page',
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRenderLite: (dataIndex) => (
  //         <Button
  //           text={pages[dataIndex].name}
  //           className="secondary--bg ml-0 size-xxxs text-nowrap"
  //           size="xxs"
  //           onClick={() => window.open(`${pages[dataIndex].url}`, '_blank')}
  //         />
  //       )
  //     },
  //   },
  //   {
  //     name: 'title',
  //     label: 'Title Tag',
  //     options: {
  //       filter: true,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: 'keywords',
  //     label: 'Meta Keywords',
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRenderLite: (dataIndex) => {
  //         const keywordsString = pages[dataIndex]?.keywords || '';
  //         const keywords = keywordsString.split(',').filter(Boolean);
          
  //         return (
  //           <div>
  //             {keywords.length > 0 ? (
  //               <>
  //                 {keywords.map((keyword) => (
  //                   <span className="badge dark-grey--bg opacity-95 white--clr fw-400 size-xxxxs me-1 mb-1">
  //                     {keyword.trim()}
  //                   </span>

  //                   // <span className="size-xxs rounded dark--bg white--clr bg-dark opacity-95 fw-400 px-2">
  //                   //   {keyword.trim()}
  //                   // </span>
  //                 ))}
  //                 {/* <p className="size-xxs opacity-95 mt-1">{keywordsString}</p> */}
  //               </>
  //             ) : (
  //               <span>-</span>
  //             )}
  //           </div>
  //         );
  //       }
  //     },
  //   },
  //   {
  //     name: 'description',
  //     label: 'Meta Description',
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRenderLite: (dataIndex) => (
  //         pages[dataIndex].description || <span>-</span>
  //       )
  //     },
  //   },
  // ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}

      {/* SETTINGS */}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <CustomBlock className="mb-30">
                <h4 className="size-lg primary--clr fw-700 mb-5">Settings</h4>
                <p className="light-grey--clr">View and Manage Website Settings</p>
              </CustomBlock>

              <CustomBlock>
                <MUIDataTable
                  data={settings}
                  columns={settingsColumns}
                  options={{
                    selectableRows: 'none',
                    download: false,
                    print: false,
                    jumpToPage: false,
                    search: false,
                    viewColumns: false,
                    filter: false,
                    pagination: false,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* PAGES SEO */}
      {/* <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <CustomBlock className="mb-30">
                <h4 className="size-lg primary--clr fw-700 mb-5">Content Pages SEO</h4>
                <p className="light-grey--clr">View and Manage SEO for Defined Pages</p>
              </CustomBlock>

              <CustomBlock>
                <MUIDataTable
                  data={pages}
                  columns={pagesSEOColumns}
                  options={{
                    selectableRows: 'none',
                    download: false,
                    print: false,
                    jumpToPage: false,
                    search: false,
                    viewColumns: false,
                    filter: false,
                    pagination: false,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock> */}

      {/* UPDATE SETTINGS MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        contentHeader="Update Setting"
        primaryModalActionText="Save Changes"
        primaryModalActionColor="success--bg"
        primaryModalActionOnClick={formik.submitForm}
        onHide={handleModalClose}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                isDisabled
                label="Name"
                errorMessage={formik.errors.key}
                inputState={`${helper.getInputClasses(formik, 'key')}`}
                {...formik.getFieldProps('key')}
                className="disabled"
              />
            </ContentBlock>

            <ContentBlock>
              <InputBlock
                label="Value"
                errorMessage={formik.errors.value}
                inputState={`${helper.getInputClasses(formik, 'value')}`}
                {...formik.getFieldProps('value')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* UPDATE PAGE SEO MODAL */}
      {/* <ModalBlock
        hasCloseAction
        isVisible={isPageSEOModalVisible}
        size="lg"
        contentHeader={`${selectedPage.name} SEO`}
        primaryModalActionText="Save Changes"
        primaryModalActionColor="success--bg"
        primaryModalActionOnClick={formikPageSEO.submitForm}
        onHide={handlePageModalClose}
      >
        <FormBlock onSubmit={formikPageSEO.submitForm}>
          <Section hasNoContainer>
            <ContentBlock className="mb-40">
              <ContentBlock>
                <InputBlock
                // isDisabled
                  label="Page Name"
                  errorMessage={formikPageSEO.errors.name}
                  inputState={`${helper.getInputClasses(formikPageSEO, 'name')}`}
                  {...formikPageSEO.getFieldProps('name')}
                />
              </ContentBlock>

              <ContentBlock>
                <InputBlock
                  label="URL"
                  errorMessage={formikPageSEO.errors.url}
                  inputState={`${helper.getInputClasses(formikPageSEO, 'url')}`}
                  {...formikPageSEO.getFieldProps('url')}
                />
              </ContentBlock>
            </ContentBlock>

            <ContentBlock>
              <InputBlock
                label="Title Tag"
                errorMessage={formikPageSEO.errors.title}
                inputState={`${helper.getInputClasses(formikPageSEO, 'title')}`}
                {...formikPageSEO.getFieldProps('title')}
              />
            </ContentBlock>

            <ContentBlock>
              <TextAreaBlock
                label="Keywords"
                errorMessage={formikPageSEO.errors.keywords}
                inputState={`${helper.getInputClasses(formikPageSEO, 'keywords')}`}
                {...formikPageSEO.getFieldProps('keywords')}
              />
            </ContentBlock>

            <ContentBlock>
              <TextAreaBlock
                label="Description"
                errorMessage={formikPageSEO.errors.description}
                inputState={`${helper.getInputClasses(formikPageSEO, 'description')}`}
                {...formikPageSEO.getFieldProps('description')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock> */}

      {/* FEATURES */}
      {/* <CustomBlock className="content-container mb-10">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">

              <CustomBlock className="mb-30">
                <h4 className="size-lg primary--clr fw-700 mb-5">Features</h4>
                <p className="light-grey--clr">Manage Accessibility of Website Features</p>
              </CustomBlock>

              <CustomBlock className="d-flex flex-column gap-3 mb-30">
                <CheckboxBlock
                  disabled
                  label="Contact Page"
                  id="isContactPageActive"
                  {...formik.getFieldProps('isContactFormActive')}
                  isChecked={formik.values.isContactFormActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
                <CheckboxBlock
                  disabled
                  label="Events Management"
                  id="isEventsManagementActive"
                  {...formik.getFieldProps('isEventsManagementActive')}
                  isChecked={formik.values.isEventsManagementActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
                <CheckboxBlock
                  disabled
                  label="Member Management"
                  id="isMemberManagementActive"
                  {...formik.getFieldProps('isMemberManagementActive')}
                  isChecked={formik.values.isMemberManagementActive}
                  labelClassName="d-flex align-items-center size-sm fw-500 m-0"
                  style={{ width: 'fit-content' }}
                />
              </CustomBlock>

              <CustomBlock className="d-flex justify-content-end">
                <Button
                  isDisabled
                  type="submit"
                  text="Save Changes"
                  className="btn success--bg"
                />
              </CustomBlock>

            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock> */}
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(SettingsListingPage);