import React, { useRef } from 'react';
// COMPONENTS
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const SocialIconButton = (props) => {
  const {
    displayIf, buttonColor, buttonText, socialIcon, onClickAction, className, title
  } = props;
  const target = useRef(null);

  if (!displayIf) return null;

  return (
    <OverlayTrigger
      placement="bottom"
      onEntering={(node) => {
        node.classList.remove('hide');
        node.classList.add('show');
      }}
      onExiting={(node) => {
        node.classList.remove('show');
        node.classList.add('hide');
      }}
      overlay={(
        <Tooltip id="overlay-tooltip" className="custom-tooltip">
          {title}
        </Tooltip>
      )}
    >
      <button
        ref={target}
        onClick={onClickAction}
        className={`social-icon ${className || ''} position-relative d-flex align-items-center justify-content-center m-0`}
        style={{ backgroundColor: buttonColor }}
      >
        {socialIcon}
        {buttonText && (
          <p className="size-xxs white--clr fw-500">
            {buttonText}
          </p>
        )}
      </button>
    </OverlayTrigger>
  );
};

export default SocialIconButton;