/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
import Image from '../../../../components/Image';
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as assetService from '../../../../services/cms/assetService';
import AssetValidator from '../../../../helpers/validators/cms/asset/AssetValidator';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

const initialAssetModel = {
  companyLogo: null,
  companyLogoPath: '',
  companyIcon: null,
  companyIconPath: '',
  companyName: '',
  emailAddress: '',
  phoneNumber: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  zipCode: '',
  primaryWeekDayHours: '',
  primaryWeekEndHours: '',
  secondaryWeekDayHours: '',
  secondaryWeekEndHours: '',
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  linkedInLink: '',
  youtubeLink: '',
  tiktokLink: '',
  googleMapLink: '',
  appStoreLink: '',
  googlePlayStoreLink: '',
  googleAnalyticsLink: '',
  seo: '',
  facebookPixelCode: ''
};

const AssetsAndResourcesPage = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [assetModel, setAsset] = useState(initialAssetModel);
  const [logoFile, setLogoFile] = useState([]);
  const [iconFile, setIconFile] = useState([]);
  const logoRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    fetchAssets();
  }, []);

  const formik = useFormik({
    initialValues: assetModel,
    validationSchema: AssetValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveAsset(values);
    },
  });

  const fetchAssets = () => {
    setIsLoading(true);

    assetService.getAsset().then((res) => {
      setAsset(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const saveAsset = (model) => {
    setIsLoading(true);

    if (logoFile.length > 0) {
      model = { ...model, companyLogo: logoFile[0].file };
    }
    if (iconFile.length > 0) {
      model = { ...model, companyIcon: iconFile[0].file };
    }
    assetService.updateAsset(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchAssets();
      setLogoFile([]);
      setIconFile([]);
      logoRef.current.removeFiles();
      iconRef.current.removeFiles();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.handleSubmit}>
              {/* RESOURCES */}
              <Section hasNoContainer className="content-container--card-style--with-shadow">
                <ContentBlock>
                  <ContentHeader
                    title="Resources"
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Company Logo (Required)"
                    subtitle="Please upload your company logo"
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <CustomBlock className="flex-center pt-15 pb-30">
                    <CustomBlock className="large-image-container">
                      <Image
                        source={assetModel.companyLogoPath.includes('http') ? assetModel.companyLogoPath : `${process.env.REACT_APP_API_URL}Attachments/${assetModel.companyLogoPath}`}
                      />
                    </CustomBlock>
                  </CustomBlock>
                  <FileUploadBlock
                    onupdatefiles={setLogoFile}
                    labelIdle="Replace Logo"
                    ref={logoRef}
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Company Icon"
                    subtitle="Please upload your company icon if you have one available"
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <CustomBlock className="flex-center pt-15 pb-30">
                    <CustomBlock className="large-image-container">
                      <Image
                        source={assetModel.companyIconPath.includes('http') ? assetModel.companyIconPath : `${process.env.REACT_APP_API_URL}Attachments/${assetModel.companyIconPath}`}
                      />
                    </CustomBlock>
                  </CustomBlock>
                  <FileUploadBlock
                    onupdatefiles={setIconFile}
                    labelIdle="Replace Logo"
                    ref={iconRef}
                  />
                </ContentBlock>
              </Section>

              {/* BASIC INFORMATION */}
              <Section hasNoContainer className="content-container--card-style--with-shadow mt-30">
                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="lg"
                  />
                </ContentBlock>

                {/* CONTACT DETAILS */}
                <ContentBlock>
                  <ContentHeader
                    title="Contact Details"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Company Name"
                    isRequired
                    errorMessage={formik.errors.companyName}
                    inputState={`${helper.getInputClasses(formik, 'companyName')}`}
                    {...formik.getFieldProps('companyName')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Email Address"
                    isRequired
                    errorMessage={formik.errors.emailAddress}
                    inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                    {...formik.getFieldProps('emailAddress')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Phone Number"
                    mask="(999) 999-9999"
                    errorMessage={formik.errors.phoneNumber}
                    inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                    {...formik.getFieldProps('phoneNumber')}
                  />
                </ContentBlock>

                {/* LOCATION */}
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Business Location"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Street Address"
                    placeholder="e.g. 100 Business Avenue"
                    errorMessage={formik.errors.streetAddress1}
                    inputState={`${helper.getInputClasses(formik, 'streetAddress1')}`}
                    {...formik.getFieldProps('streetAddress1')}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="Ste/Bldg No."
                    placeholder="e.g. Ste. A"
                    errorMessage={formik.errors.streetAddress2}
                    inputState={`${helper.getInputClasses(formik, 'streetAddress2')}`}
                    {...formik.getFieldProps('streetAddress2')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <InputBlock
                    label="City"
                    errorMessage={formik.errors.city}
                    inputState={`${helper.getInputClasses(formik, 'city')}`}
                    {...formik.getFieldProps('city')}
                  />
                </ContentBlock>

                <ContentBlock cols={2}>
                  <InputBlock
                    label="State"
                    placeholder="Eg: Louisiana"
                    errorMessage={formik.errors.state}
                    inputState={`${helper.getInputClasses(formik, 'state')}`}
                    {...formik.getFieldProps('state')}
                  />
                </ContentBlock>

                <ContentBlock cols={1}>
                  <InputBlock
                    label="Zip Code"
                    mask="99999"
                    errorMessage={formik.errors.zipCode}
                    inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                    {...formik.getFieldProps('zipCode')}
                  />
                </ContentBlock>

                {/* HOURS */}
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Business Hours (Optional)"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Weekday Hours"
                    placeholder="e.g. 100 Mon-Thu: 11 AM - 8 PM"
                    errorMessage={formik.errors.primaryWeekDayHours}
                    inputState={`${helper.getInputClasses(formik, 'primaryWeekDayHours')}`}
                    {...formik.getFieldProps('primaryWeekDayHours')}
                  />

                  <InputBlock
                    label="Weekday Hours"
                    placeholder="e.g. 100 Fri: 11 AM - 10 PM"
                    errorMessage={formik.errors.secondaryWeekDayHours}
                    inputState={`${helper.getInputClasses(formik, 'secondaryWeekDayHours')}`}
                    {...formik.getFieldProps('secondaryWeekDayHours')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Weekend Hours"
                    placeholder="e.g. 100 Sat: 11 AM - 6 PM"
                    errorMessage={formik.errors.primaryWeekEndHours}
                    inputState={`${helper.getInputClasses(formik, 'primaryWeekEndHours')}`}
                    {...formik.getFieldProps('primaryWeekEndHours')}
                  />

                  <InputBlock
                    label="Weekend Hours"
                    placeholder="e.g. 100 Sun: Closed"
                    errorMessage={formik.errors.secondaryWeekEndHours}
                    inputState={`${helper.getInputClasses(formik, 'secondaryWeekEndHours')}`}
                    {...formik.getFieldProps('secondaryWeekEndHours')}
                  />
                </ContentBlock>
              </Section>

              {/* SOCIAL MEDIA AND LINKS */}
              <Section hasNoContainer className="content-container--card-style--with-shadow mt-30">
                <ContentBlock>
                  <ContentHeader
                    title="Social Media and Links"
                    headerSize="lg"
                  />
                </ContentBlock>

                {/* SOCIAL MEDIA AND OTHER LINKS */}
                <ContentBlock>
                  <ContentHeader
                    title="Social Media and Links"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Facebook"
                    placeholder="e.g. https://www.facebook.com/primtekllc/"
                    errorMessage={formik.errors.facebookLink}
                    inputState={`${helper.getInputClasses(formik, 'facebookLink')}`}
                    {...formik.getFieldProps('facebookLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Instagram"
                    placeholder="e.g. https://www.instagram.com/primtekllc/"
                    errorMessage={formik.errors.instagramLink}
                    inputState={`${helper.getInputClasses(formik, 'instagramLink')}`}
                    {...formik.getFieldProps('instagramLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Twitter"
                    placeholder="e.g. https://twitter.com/primtekllc"
                    errorMessage={formik.errors.twitterLink}
                    inputState={`${helper.getInputClasses(formik, 'twitterLink')}`}
                    {...formik.getFieldProps('twitterLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="LinkedIn"
                    placeholder="e.g. https://www.linkedin.com/company/primtek-llc/"
                    errorMessage={formik.errors.linkedInLink}
                    inputState={`${helper.getInputClasses(formik, 'linkedInLink')}`}
                    {...formik.getFieldProps('linkedInLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Youtube Channel"
                    placeholder="Paste Youtube Channel link here"
                    errorMessage={formik.errors.youtubeLink}
                    inputState={`${helper.getInputClasses(formik, 'youtubeLink')}`}
                    {...formik.getFieldProps('youtubeLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Tiktok Channel"
                    placeholder="Paste Tiktok Channel link here"
                    inputState={`${helper.getInputClasses(formik, 'tiktokLink')}`}
                    {...formik.getFieldProps('tiktokLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Google Maps Link"
                    placeholder="Paste Google Maps directions link here"
                    errorMessage={formik.errors.googleMapLink}
                    inputState={`${helper.getInputClasses(formik, 'googleMapLink')}`}
                    {...formik.getFieldProps('googleMapLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="App Store Link"
                    placeholder="Paste app store link here"
                    errorMessage={formik.errors.appStoreLink}
                    inputState={`${helper.getInputClasses(formik, 'appStoreLink')}`}
                    {...formik.getFieldProps('appStoreLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Google Playstore Link"
                    placeholder="Paste Google playstore link here"
                    errorMessage={formik.errors.googlePlayStoreLink}
                    inputState={`${helper.getInputClasses(formik, 'googlePlayStoreLink')}`}
                    {...formik.getFieldProps('googlePlayStoreLink')}
                  />
                </ContentBlock>
              </Section>

              {/* SEO TOOLS */}
              <Section hasNoContainer className="content-container--card-style--with-shadow mt-30 mb-120">
                <ContentBlock>
                  <ContentHeader
                    title="Search Engine Optimization Tools"
                    headerSize="lg"
                  />
                </ContentBlock>

                {/* SEO ITEMS */}
                <ContentBlock cols={4}>
                  <InputBlock
                    label="Google Analytics Id"
                    placeholder="Enter Google Analytics ID e.g. UA-21374549-2"
                    className="xl"
                    errorMessage={formik.errors.googleAnalyticsLink}
                    inputState={`${helper.getInputClasses(formik, 'googleAnalyticsId')}`}
                    {...formik.getFieldProps('googleAnalyticsId')}
                  />
                </ContentBlock>
                {/* <ContentBlock cols={6}>
                  <TextAreaBlock
                    label="Facebook Pixel Code"
                    className="xl"
                    {...formik.getFieldProps('facebookPixelCode')}
                  />
                </ContentBlock> */}
              </Section>

              {/* FIXED ACTIONS BAR */}
              <CustomBlock className="fixed-actions-bar">
                <CustomBlock className="left-action-block small-bar">
                  <CustomBlock className="input-item action">
                    {/* <Button
                      text="Back"
                      className="secondary--bg ml-0"
                    /> */}
                  </CustomBlock>
                </CustomBlock>

                <CustomBlock className="content-container--actions small-bar">
                  <Button
                    text="Cancel"
                    className="danger--bg"
                  />

                  <Button
                    text="Save Changes"
                    className="primary--bg"
                    onClick={formik.handleSubmit}
                  />
                </CustomBlock>
              </CustomBlock>

            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(AssetsAndResourcesPage);