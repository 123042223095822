import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { GoTypography } from 'react-icons/go';
import { FiInfo } from 'react-icons/fi';
import CustomBlock from '../../components/CustomBlock';
import Banner from '../../components/Banner';
import BannerImage from '../../assets/client/default-banner.png';
import FluidTypographyTest from './FluidTypographyTest';

const DevelopmentReferencePage = (props) => {
  const { cmsInfo } = props;
  const [cmsInfoStringified, setCmsInfoStringified] = useState('');
  const [showFluidTypographyTest, setShowFluidTypographyTest] = useState(false);
  const [cmsInfoVisible, setCmsInfoVisible] = useState(false);
  const [fontSizes, setFontSizes] = useState({});

  const sizeClasses = [
    'xxxl',
    'xxl',
    'xl',
    'lg',
    'mdlg',
    'md',
    'sm',
    'xs',
    'xxs',
    'xxxs',
  ];

  const refs = useRef(sizeClasses.reduce((acc, sizeClass) => {
    acc[sizeClass] = React.createRef();
    return acc;
  }, {}));

  const updateFontSizes = () => {
    const newFontSizes = {};
    sizeClasses.forEach((sizeClass) => {
      if (refs.current[sizeClass].current) {
        const computedStyle = getComputedStyle(refs.current[sizeClass].current);
        newFontSizes[sizeClass] = computedStyle.fontSize;
      }
    });
    setFontSizes(newFontSizes);
  };

  useEffect(() => {
    if (cmsInfo) {
      // console.log(cmsInfo);
      setCmsInfoStringified(JSON.stringify(cmsInfo, null, 2));
    }
  }, [cmsInfo]);

  useEffect(() => {
    updateFontSizes();
    window.addEventListener('resize', updateFontSizes);
    return () => window.removeEventListener('resize', updateFontSizes);
  }, []);

  useEffect(() => {
    updateFontSizes();
  }, [showFluidTypographyTest, cmsInfoVisible]);

  // STYLES FOR FORMATTED JSON DISPLAY
  const preStyle = {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    background: '#f5f5f5',
    padding: '40px',
    overflow: 'auto',
    maxHeight: '500px',
    fontSize: '0.9rem',
    lineHeight: '1.4',
  };

  return (
    <>
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Development Reference Page"
        imageSource={BannerImage}
      />
      
      <CustomBlock className="main-content">

        {/* FLUID TYPOGRAPHY TEST */}
        {showFluidTypographyTest && (
          <CustomBlock className="content-section">
            <FluidTypographyTest
              className="w-100"
            />
          </CustomBlock>
        )}

        {/* CMS INFO */}
        {cmsInfoVisible && (
          <CustomBlock className="content-section">
            <pre
              className="primary-lighter--bg white--clr rounder"
              style={preStyle}
            >
              {cmsInfoStringified}
            </pre>
          </CustomBlock>
        )}

        <CustomBlock className="content-section">
          <CustomBlock className="d-flex flex-wrap align-items-center gap-60">
            <CustomBlock className="d-flex flex-column">
              <h1>H1 Heading Tag</h1>
              <h2>H2 Heading Tag</h2>
              <h3>H3 Heading Tag</h3>
              <h4>H4 Heading Tag</h4>
              <h5>H5 Heading Tag</h5>
              <h6>H6 Heading Tag</h6>
            </CustomBlock>

            <CustomBlock className="d-flex flex-column gap-5">
              {sizeClasses.map((sizeClass) => (
                <p
                  key={sizeClass}
                  ref={refs.current[sizeClass]}
                  className={`size-${sizeClass} d-flex align-items-center justify-content-between gap-40`}
                >
                  {sizeClass}
                  <span className="size-xxs primary--bg white--clr round py-1 px-2">
                    {fontSizes[sizeClass] || 'Calculating...'}
                  </span>
                </p>
              ))}
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      <CustomBlock
        className="position-fixed d-flex flex-column gap-10"
        style={{
          bottom: '10px',
          left: '10px',
          zIndex: '1000',
        }}
      >
        <Button
          onClick={() => setCmsInfoVisible((prev) => !prev)}
          className="d-flex align-items-center justify-content-center rounded-circle"
          style={{
            width: '3rem',
            height: '3rem',
          }}
        >
          <FiInfo className="size-lg" />
        </Button>

        <Button
          onClick={() => setShowFluidTypographyTest((prev) => !prev)}
          className="d-flex align-items-center justify-content-center rounded-circle"
          style={{
            width: '3rem',
            height: '3rem',
          }}
        >
          <GoTypography className="size-lg" />
        </Button>
      </CustomBlock>
    </>
  );
};

export default DevelopmentReferencePage;